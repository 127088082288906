import { Box, Button, Container, Grid, InputBase, Link, Rating, Skeleton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../../components/navbar";
import FollowingListWidget from "../widgets/FollowingListWidget";

import WidgetWrapper from "../../components/CustomStyledComponents/WidgetWrapper";
import UserAvatar from "../../components/CustomStyledComponents/UserAvatar";

import UserWidget from "../widgets/UserWidget";

import PostsWidget from "../widgets/PostsWidget";
import MyPostWidget from "../widgets/MyPostWidget";

import FlexBetween from "../../components/CustomStyledComponents/FlexBetween";

import style from './style.module.scss'
import headerBG from '../../assets/images/profile-bg.png'
import riseUpLogo from '../../assets/images/rise-up.png'
import mapIcon from '../../assets/images/map-icon.png'
import linkIcon from '../../assets/images/link-icon.png'
import likeIcon from '../../assets/images/favorite.png'
import moreIcon from '../../assets/images/more_horiz.png'
import profileIcon from '../../assets/images/person-icon.png'
import smsIcon from '../../assets/images/sms.png'
import placeholderImage from '../../assets/images/videoplaceholder.png'

import { SERVER_URL } from "../../service/config";


const Profile = () => {
    const members = [
        { name: "Joe Doe", title: "President" },
        { name: "Jane Smith", title: "Vice President" },
        { name: "John Appleseed", title: "Manager" },
        { name: "Joe Doe", title: "President" },
        { name: "Jane Smith", title: "Vice President" },
        { name: "John Appleseed", title: "Manager" },
        { name: "Joe Doe", title: "President" },
        { name: "Jane Smith", title: "Vice President" },
        { name: "John Appleseed", title: "Manager" }
    ];

    const [user, setUser] = useState();
    const [isSignedInUserprofile, setIsSignedInUserprofile] = useState(false);

    const navigate = useNavigate();

    const token = useSelector((state) => state.common.token);
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

    const userState = useSelector((state) => state.common.user);
    const signedInUsername = userState?.username;
    const { username } = useParams();
    const { palette } = useTheme();

    const getUser = async () => {
        const targetUsername = username || signedInUsername;

        const response = await fetch(SERVER_URL + `u/${targetUsername}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const userData = await response.json();
            setIsSignedInUserprofile(userData.username === signedInUsername);
            setUser(userData);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    return (
        <>
        <Navbar />
            <Box sx={{ minHeight: '360px' }}>
                <img
                    src={headerBG}
                    alt="Background"
                    style={{
                        width: '100%',
                        minHeight: '360px',
                        objectFit: 'cover'
                    }}
                />
            </Box>
            <Container maxWidth="xl" >
                <Grid container spacing={6} className={style.mainBox}>
                    {!user ? (
                        <Grid item xs={12} md={4}>
                            {/* Wrapper */}
                            <WidgetWrapper>
                                {/* Top Section - Logo and Username */}
                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="10px">
                                    <Skeleton variant="circular" width={80} height={80} />
                                    <Skeleton variant="text" width="60%" height={30} />
                                    <FlexBetween>
                                        <Skeleton variant="text" width="30%" height={20} />
                                        <Skeleton variant="rectangular" width={20} height={20} />
                                    </FlexBetween>
                                </Box>

                                {/* Stats Section */}
                                <Box display="flex" justifyContent="space-between" marginTop="20px">
                                    <Box textAlign="center">
                                        <Typography variant="h5" color="#666">
                                            Rank
                                        </Typography>
                                        <Skeleton variant="text" width={50} height={50} />
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="h5" color="#666">
                                            Rating
                                        </Typography>
                                        <Skeleton variant="text" width={50} height={30} />
                                        <Skeleton variant="rectangular" width={100} height={20} />
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="h5" color="#666">
                                            Members
                                        </Typography>
                                        <Skeleton variant="text" width={50} height={50} />
                                    </Box>
                                </Box>

                                {/* Badges Section */}
                                <FlexBetween sx={{ marginTop: "20px", padding: "0 10px" }}>
                                    <Typography variant="h5" color="#666">
                                        Badges
                                    </Typography>
                                    <Skeleton variant="text" width={60} height={20} />
                                </FlexBetween>
                                <Box display="flex" gap="10px" marginTop="10px">
                                    <Skeleton variant="rectangular" width={50} height={50} />
                                    <Skeleton variant="rectangular" width={50} height={50} />
                                    <Skeleton variant="rectangular" width={50} height={50} />
                                    <Skeleton variant="rectangular" width={50} height={50} />
                                </Box>

                                {/* Performance Section */}
                                <Box marginTop="20px">
                                    <Typography variant="h5" color="#666" marginBottom="10px">
                                        Performance
                                    </Typography>
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={150}
                                        sx={{ borderRadius: "12px", backgroundColor: "rgba(217, 217, 217, 0.28)" }}
                                    />
                                </Box>

                                {/* Buttons Section */}
                                <FlexBetween sx={{ marginTop: "20px", gap: "10px" }}>
                                    <Skeleton variant="rectangular" width="30%" height={40} />
                                    <Skeleton variant="rectangular" width="30%" height={40} />
                                    <Skeleton variant="rectangular" width="30%" height={40} />
                                </FlexBetween>
                            </WidgetWrapper>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={4} className={style.leftBox}>
                            <Box className={style.topLogoBox}>
                                {/* Logo Image */}
                                <img
                                    src={(user?.profilePhotoUrl?.length > 0) ? user.profilePhotoUrl[0].url : 'https://i.stack.imgur.com/l60Hf.png'}
                                    alt="Logo"
                                    className={style.logoImage}
                                />
                                <Box>
                                    <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '24px', color: '#000000', mb: 1, textAlign: 'center' }}>
                                        {user?.username}
                                    </Typography>
                                    <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '400', fontSize: '20px', color: '#CCCACA' }}>
                                        <img
                                            src={mapIcon}
                                            alt="Map"
                                            style={{
                                                width: '18px',
                                                marginRight: '6px'
                                            }}
                                        />
                                        Tunis
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: '10px', marginTop: '20px' }}>
                                <Box>
                                    <Typography variant="h5" sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '20px', color: '#666', gap: '20px' }}>
                                        Rank
                                        <span style={{ textAlign: 'center', fontWeight: '500', fontSize: '36px', color: '#000' }}>1</span>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} >
                                    <Typography variant="h5" sx={{ display: 'flex', fontWeight: '400', fontSize: '20px', color: '#666', gap: '15px' }}>
                                        Rating
                                        <span style={{ fontWeight: '500', color: '#000' }}>4.7</span>
                                    </Typography>
                                    <Rating
                                        name="event-rating"
                                        defaultValue={5}
                                        precision={5}
                                        readOnly
                                    />

                                </Box>
                                <Box>
                                    <Typography variant="h5" sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '20px', color: '#666', gap: '20px' }}>
                                        Members
                                        <span style={{ textAlign: 'center', fontWeight: '500', fontSize: '36px', color: '#000' }}>24</span>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px', marginTop: '20px', padding: '0 10px' }}>
                                <Typography variant="h5" sx={{ fontWeight: '400', fontSize: '24px', color: '#666' }}>
                                    Badges
                                </Typography>
                                <Link href="#" sx={{ fontWeight: '400', fontSize: '20px', color: '#14B3C1', textDecoration: 'none' }}>See All</Link>
                            </Box>
                            <Box className={style.badges}>
                                <Box className={style.item}></Box>
                                <Box className={style.item}></Box>
                                <Box className={style.item}></Box>
                                <Box className={style.item}></Box>
                            </Box>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: '400', fontSize: '24px', color: '#666', margin: '20px 0' }}>
                                    Performance
                                </Typography>
                                <Box sx={{ borderRadius: '12px', backgroundColor: 'rgba(217, 217, 217, 0.28)', alignContent: 'center', height: '150px' }}>
                                    <Typography variant="h5" sx={{ fontWeight: '400', fontSize: '20px', color: '#666', textAlign: 'center' }}>
                                        Loading...
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={style.btnBox}>
                                <Button variant="contained" size="large" className={style.enroll}>
                                    Enroll
                                </Button>
                                <Button variant="contained" size="large" className={style.generate}>
                                    Genrate Link
                                    <img
                                        src={linkIcon}
                                        alt="Link"
                                        style={{
                                            width: '22px',
                                        }}
                                    />
                                </Button>
                                <Button variant="contained" size="large" className={style.collaborate}>
                                    Collaborate
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} md={8} className={style.rightBox}>
                        {!user ? (
                            <WidgetWrapper>
                                {/* About Us Title */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 2,
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "#666",
                                    }}
                                >
                                    About Us
                                </Typography>

                                {/* About Us Content */}
                                <Skeleton variant="text" width="100%" height={60} sx={{ mb: 3 }} />
                                <Skeleton variant="text" width="90%" height={60} sx={{ mb: 3, mx: "auto" }} />

                                {/* Launch Date Section */}
                                <Box
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "24px",
                                            color: "#666",
                                        }}
                                    >
                                        Launch Date
                                    </Typography>
                                    <Skeleton variant="text" width={120} height={30} />
                                </Box>

                                {/* Sector of Activities Section */}
                                <Box
                                    sx={{
                                        mt: 3,
                                        mb: 3,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "#666",
                                        }}
                                    >
                                        Sector of activities
                                    </Typography>
                                    <Skeleton variant="text" width={180} height={30} />
                                </Box>

                                {/* Administrative Board Title */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 3,
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "#666",
                                    }}
                                >
                                    Administrative Board
                                </Typography>

                                {/* Administrative Board Members */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "20px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {[...Array(4)].map((_, index) => (
                                        <Box key={index} sx={{ width: "calc(50% - 10px)", textAlign: "center" }}>
                                            <Skeleton
                                                variant="rectangular"
                                                width={80}
                                                height={80}
                                                sx={{ borderRadius: "50%", margin: "0 auto" }}
                                            />
                                            <Skeleton variant="text" width="60%" height={30} sx={{ mx: "auto", mt: 1 }} />
                                            <Skeleton variant="text" width="80%" height={20} sx={{ mx: "auto", mt: 1 }} />
                                        </Box>
                                    ))}
                                </Box>
                            </WidgetWrapper>
                        ) : (
                            <Box className={`${style.aboutContent} ${style.boxstyle}`}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 2,
                                        fontWeight: 500,
                                        fontSize: '24px',
                                        color: '#666',
                                    }}
                                >
                                    About Us
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        m: '0',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                        color: '#000',
                                        textAlign: 'center',
                                        lineHeight: '1.5',

                                    }}
                                >
                                    Un club est une organisation formée par un groupe de personnes partageant un intérêt commun, qu'il soit sportif, culturel, social ou académique. Les membres se réunissent régulièrement pour organiser des activités, des événements et des projets en lien avec leur passion commune, favorisant ainsi les échanges, l'apprentissage, et la cohésion sociale.
                                </Typography>
                                <Box
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '10px'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '24px',
                                            color: '#666',
                                        }}
                                    >
                                        Launch Date
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            flexGrow: '1',
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            fontSize: '24px',
                                            color: '#000',
                                        }}
                                    >
                                        10 / 10 / 2020
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        mt: 3,
                                        mb: 3,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '10px'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '24px',
                                            color: '#666',
                                        }}
                                    >
                                        Sector of activities
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            flexGrow: '1',
                                            textAlign: 'center',
                                            fontWeight: 400,
                                            fontSize: '24px',
                                            color: '#000',
                                        }}
                                    >
                                        Academic and Professional
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 3,
                                        fontWeight: 500,
                                        fontSize: '24px',
                                        color: '#666',
                                    }}
                                >
                                    Adiminstrative Board
                                </Typography>
                                <Box className={style.adminbox}>
                                    {members.map((member, index) => (
                                        <Box className={style.boxItem} key={index}>
                                            <Box className={style.image}></Box>
                                            <Box className={style.conetnttext}>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: 500,
                                                        fontSize: '22px',
                                                        color: '#00',
                                                    }}
                                                >
                                                    {member.name}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        color: '#666',
                                                    }}
                                                >
                                                    {member.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {isSignedInUserprofile && (
                            <Box sx={{ marginTop: '10px' }}>
                                <MyPostWidget profilePhotoUrl={user.profilePhotoUrl} />
                                <Box m="2rem 0" />
                            </Box>
                        )}
                        {/* <Box className={`${style.commentForm} ${style.boxstyle}`}>
                            <div className={style.profileIcon}>
                                <img
                                    src={profileIcon}
                                />
                            </div>
                            <InputBase
                                placeholder="What’s on your mind ?"
                            />
                            <Button variant="contained" size="large" className={style.postBtn}>
                                Post
                            </Button>
                        </Box> */}
                        {/* {[...Array(6)].map((_, index) => (
                            <Box key={index} className={`${style.profileDetail} ${style.boxstyle}`}>
                                <Box className={style.titleHeader}>
                                    <div className={style.profileIcon}>
                                        <img
                                            src={profileIcon}
                                        />
                                    </div>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h4" sx={{ fontWeight: '700', fontSize: '16px', color: '#2E2E2E', mb: 0.5 }}>
                                            RiseUp Club
                                        </Typography>
                                        <Typography variant="h5" sx={{ fontWeight: '400', fontSize: '13px', color: '#828282' }}>
                                            <span>5:16 PM</span> - <span>25 August,2024</span>
                                        </Typography>
                                    </Box>
                                    <Button variant="contained" size="large" className={style.moreBtn}>
                                        <img
                                            src={moreIcon}
                                            style={{
                                                width: '50%',
                                            }}
                                        />
                                    </Button>
                                </Box>
                                <Typography variant="h4" sx={{ fontWeight: '400', fontSize: '16px', color: '#2E2E2E', mt: 1, mb: 1 }}>
                                    Video showcasing our last Event
                                </Typography>
                                <Box className={style.featureBox}>
                                    <img
                                        src={placeholderImage}
                                    />
                                </Box>
                                <Box className={style.btnsBox}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <Button variant="contained" size="large" className={style.fill}>
                                            <img
                                                src={smsIcon}
                                                style={{
                                                    width: '50%',
                                                }}
                                            />
                                        </Button>
                                        <Button variant="contained" size="large" className={style.fill}>
                                            <img
                                                src={likeIcon}
                                                style={{
                                                    width: '50%',
                                                }}
                                            />
                                        </Button>
                                    </Box>
                                    <Button variant="contained" size="large" className={style.noFill}>
                                        Send your Feedback
                                    </Button>
                                </Box>
                            </Box>
                        ))} */}
                        {!user ? (
                            Array.from(new Array(3)).map((el, index) => (
                                <WidgetWrapper marginBottom="2rem" key={index}>
                                    {/* Following skeleton  */}
                                    <FlexBetween>
                                        <FlexBetween gap="1rem">
                                            <UserAvatar isLoading={true} size="55px" />

                                            <Box>
                                                <Skeleton
                                                    width="150px"
                                                    height={25}
                                                    style={{ marginBottom: "0.25rem" }}
                                                />
                                                <Skeleton width="75px" height={20} />
                                            </Box>
                                        </FlexBetween>

                                        <FlexBetween>
                                            <Skeleton
                                                variant="circle"
                                                width={30}
                                                height={30}
                                                style={{ padding: "0.6rem", borderRadius: "50%" }}
                                            />
                                        </FlexBetween>
                                    </FlexBetween>

                                    <Skeleton
                                        width="100%"
                                        height={20}
                                        style={{ marginTop: "1rem" }}
                                    />
                                    <Skeleton width="50%" height={20} />

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Skeleton
                                            variant="rect"
                                            width="100%"
                                            height="25rem"
                                            style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
                                        />
                                    </div>

                                    <FlexBetween mt="0.25rem">
                                        <FlexBetween gap="1rem">
                                            <FlexBetween gap="0.3rem">
                                                <Skeleton
                                                    variant="circle"
                                                    width={24}
                                                    height={24}
                                                    style={{ borderRadius: "50%" }}
                                                />
                                            </FlexBetween>

                                            <FlexBetween gap="0.3rem">
                                                <Skeleton
                                                    variant="circle"
                                                    width={24}
                                                    height={24}
                                                    style={{ borderRadius: "50%" }}
                                                />
                                            </FlexBetween>
                                        </FlexBetween>

                                        <Skeleton
                                            variant="circle"
                                            width={24}
                                            height={24}
                                            style={{ borderRadius: "50%" }}
                                        />
                                    </FlexBetween>
                                </WidgetWrapper>
                            ))
                        ) : (
                            <Box sx={{ marginTop: '10px' }}>
                                <PostsWidget isProfile={true} username={user?.username} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Profile;